import React, { useState } from 'react';
import styled from '@emotion/styled';
import { css, useTheme } from '@emotion/react';
import Lightbox from 'react-image-lightbox';
import { Typography, Img } from '@/components/atoms';
import ImageDataType from '@/types/data/image';
import mq from '@/styles/mq';

import 'react-image-lightbox/style.css';

interface ProductHeaderProps {
    title: string;
    shortDesc: string;
    brandImg: ImageDataType;
    gallery: {
        product_image: ImageDataType;
    }[];
}

const ProductHeaderRoot = styled.section`
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    align-items: center;
    padding-top: ${({ theme }) => theme.spacing(1)};
    padding-left: ${({ theme }) => theme.spacing(1)};
    padding-right: ${({ theme }) => theme.spacing(1)};
    padding-bottom: ${({ theme }) => theme.spacing(1)};
    ${mq('xs')} {
        padding-top: ${({ theme }) => theme.spacing(3)};
        padding-left: ${({ theme }) => theme.spacing(3)};
        padding-right: ${({ theme }) => theme.spacing(3)};
        padding-bottom: ${({ theme }) => theme.spacing(3)};
    }
    ${mq('md')} {
        padding-top: ${({ theme }) => theme.spacing(5)};
        padding-left: ${({ theme }) => theme.spacing(5)};
        padding-right: ${({ theme }) => theme.spacing(5)};
        padding-bottom: ${({ theme }) => theme.spacing(5)};
    }
    ${mq('lg')} {
        flex-direction: row;
        align-items: flex-start;
    }
`;

const GallerySection = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: ${({ theme }) => theme.spacing(3)};
    ${mq('sm')} {
        width: 70%;
    }
    ${mq('lg')} {
        margin-top: ${({ theme }) => theme.spacing(0)};
        width: 40%;
    }
`;

const MainImageContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;

const MainImage = styled(Img)`
    width: 40%;
`;

const MiniGalleryContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: ${({ theme }) => theme.spacing(2)};
`;

const GalleryImageContainer = styled.div`
    transition: 0.5s;
    border-bottom: 0.5px solid transparent;
    padding: ${({ theme }) => theme.spacing(0.5)};
    cursor: pointer;
    margin-right: ${({ theme }) => theme.spacing(1)};
`;

const GalleryImage = styled(Img)`
    width: 30px;
    height: 30px;
    ${mq('xxs')} {
        width: 40px;
        height: 40px;
    }
    ${mq('sm')} {
        width: 50px;
        height: 50px;
    }
    ${mq('md')} {
        width: 60px;
        height: 60px;
    }
    ${mq('xl')} {
        width: 70px;
        height: 70px;
    }
`;

const ContentSection = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* ${mq('sm')} {
        width: 80%;
    } */
    ${mq('lg')} {
        width: 60%;
        align-items: flex-start;
    }
`;

const Title = styled(Typography)`
    color: ${({ theme }) => theme.color.primary.main};
`;

const Description = styled(Typography)``;

const Brand = styled(Img)`
    width: 100px;
    height: 50px;
`;

const ProductHeader = ({ title, shortDesc, brandImg, gallery }: ProductHeaderProps): JSX.Element => {
    const { color } = useTheme();
    const [mainImage, setMainImage] = useState(gallery[0]?.product_image);
    const [index, setIndex] = useState<number>(0);
    const [open, setOpen] = useState<boolean>(false);

    return (
        <ProductHeaderRoot>
            <GallerySection>
                <MainImageContainer onClick={() => setOpen(true)}>
                    <MainImage image={mainImage} />
                </MainImageContainer>
                <MiniGalleryContainer>
                    {gallery?.map((image, i) => (
                        <GalleryImageContainer css={i === index && css`border-bottom: 0.5px solid ${color.primary.main};`} key={i} onClick={() => {setMainImage(image?.product_image); setIndex(i)}}>
                            <GalleryImage image={image?.product_image} />
                        </GalleryImageContainer>
                    ))}
                </MiniGalleryContainer>
            </GallerySection>
            <ContentSection>
                <Title variant="h2" as="h1">
                    {title}
                </Title>
                <Description variant="textMd" as="p">
                    {shortDesc}
                </Description>
                <Brand image={brandImg} />
            </ContentSection>
            {open && (
                <Lightbox
                    mainSrc={mainImage?.gatsbyImageData?.images?.fallback?.src || ``}
                    onCloseRequest={() => { setOpen(false) }}
                    enableZoom={true}
                    imagePadding={55}
                />
            )}
        </ProductHeaderRoot>
    );
};

export default ProductHeader;