import React from 'react';
import styled from '@emotion/styled';

import { ContactBox } from '@/components/molecules';
import { PrismicProduct } from '@/domain/PrismicProduct';
import ProductHeader from '@/containers/Product/ProductHeader';
import ProductContent from '@/containers/Product/ProductContent';
interface ProductContainerProps {
    data: {
        prismicProduct: PrismicProduct;
    };
    lang: string;
}

const ProductContainerRoot = styled.section``;

const ProductContainer = ({ data, lang }: ProductContainerProps): JSX.Element => {
    const { product_title, short_description, product_brand, product_gallery, long_description } = data?.prismicProduct?.data;

    const Header = {
        title: product_title?.text,
        shortDesc: short_description?.text,
        brandImg: product_brand,
        gallery: product_gallery,
    }

    const Content = {
        longDescription: long_description?.raw,
        lang
    }

    return (
        <ProductContainerRoot>
            <ProductHeader {...Header} />
            <ProductContent {...Content} />
            <ContactBox lang={lang} />
        </ProductContainerRoot>
    );
};

export default ProductContainer;