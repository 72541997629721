import React from 'react';
import { RichTextBlock } from 'prismic-reactjs';
import styled from '@emotion/styled';
import { css, useTheme } from '@emotion/react';
import { Typography, Img } from '@/components/atoms';
import { Content } from '@/components/molecules';
import mq from '@/styles/mq';


interface ProductContentProps {
    longDescription: RichTextBlock[];
    lang: string;
}

interface Description {
    [key: string]: string;
}

const ProductContentHeader = styled.div`
    width: 100%;
    margin: auto;
    /* border-bottom: 0.5px solid ${({ theme }) => theme.color.textPrimary.main}; */
    margin-top: ${({ theme }) => theme.spacing(3)};
    padding-top: ${({ theme }) => theme.spacing(1)};
    padding-left: ${({ theme }) => theme.spacing(1)};
    padding-right: ${({ theme }) => theme.spacing(1)};
    padding-bottom: ${({ theme }) => theme.spacing(1)};
    ${mq('xs')} {
        margin-top: ${({ theme }) => theme.spacing(0)};
        padding-top: ${({ theme }) => theme.spacing(3)};
        padding-left: ${({ theme }) => theme.spacing(3)};
        padding-right: ${({ theme }) => theme.spacing(3)};
        padding-bottom: ${({ theme }) => theme.spacing(3)};
    }
    ${mq('md')} {
        padding-top: ${({ theme }) => theme.spacing(5)};
        padding-left: ${({ theme }) => theme.spacing(5)};
        padding-right: ${({ theme }) => theme.spacing(5)};
        padding-bottom: ${({ theme }) => theme.spacing(5)};
    }
`;

const Line = styled.div`
    height: 1px;
    background-color: ${({ theme }) => theme.color.textPrimary.main};
    margin-top: ${({ theme }) => theme.spacing(2)};
`;

const ProductContentRoot = styled.section`

`;


const description: Description = {
    'fr-be': 'Description',
    'en-gb': 'Description',
    'nl-be': 'Beschrijving'
}

const ProductContent = ({ longDescription, lang }: ProductContentProps): JSX.Element => {
    const desc = description[lang];

    return (
        <ProductContentRoot>
            <ProductContentHeader>
                <Typography variant="textLg" as="p" css={css`font-weight: bold;`}>
                    {desc}
                </Typography>
                <Line />
            </ProductContentHeader>
            <Content content={longDescription} />
        </ProductContentRoot>
    );
};

export default ProductContent;