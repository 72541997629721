import React from 'react';
import { graphql } from 'gatsby';
import { SEO } from '@/components/atoms';
import { Layout } from '@/components/organisms';

import ProductContainer from '@/containers/Product/ProductContainer';
import { PrismicProduct } from '@/domain/PrismicProduct';
import { PageContext } from '@/domain/PageContext';

interface ProductTemplateProps {
    data: {
        prismicProduct: PrismicProduct;
    };
    pageContext: PageContext;
}

export const query = graphql`
  query ($id: String!) {
    prismicProduct(id: { eq: $id }) {
    id
    lang
    uid
    alternate_languages {
      lang
      uid
    }
    data {
      product_title {
        text
      }
      short_description {
        text
      }
      product_brand {
        alt
        gatsbyImageData(placeholder: BLURRED)
      }
      long_description {
        raw
      }
      product_gallery {
        product_image {
          alt
          gatsbyImageData(placeholder:BLURRED)
        }
      }
    }
  }
  }
`;

const ProductTemplate = ({
    data,
    pageContext,
}: ProductTemplateProps): JSX.Element => {
    const { finalAlternateProducts, alternateLinkProd } = pageContext;
    const { lang, uid } = data?.prismicProduct;
    const title = data?.prismicProduct?.data?.product_title?.text;

    const selectlang = {
        lang,
        uid: uid,
        alternate_languages: finalAlternateProducts,
    };
    const seo = {
        title: title,
        description: title,
        lang: lang,
        url: `${lang}`,
    };
    const linkAlternate = alternateLinkProd?.map((data) => ({
        lang: data?.lang,
        url: `${data?.lang}/${data?.uid}`,
    }));

    return (
        <Layout selectLang={selectlang}>
            <SEO
                lang={seo.lang}
                title={`${seo.title}`}
                url={seo.url}
                description={seo.description}
                alternate={linkAlternate}
            />
            <ProductContainer data={data} lang={lang} />
        </Layout>
    );
};

export default ProductTemplate;
